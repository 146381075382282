class removeMaintenanceFields {
  constructor() {
    this.iterateLinks();
  }

  iterateLinks() {
    document.addEventListener("click", (e) => {
      if (e.target && e.target.className == "remove-maintenance-fields") {
        this.handleClick(e.target, e);
        // Dopo la rimozione dell'ordine, aggiorna lo stato dei link "rimuovi ordine"

        this.updateRemoveMaintenanceLinks();

      }
    });
  }

  handleClick(link, e) {
    if (!link || !e) return;
    e.preventDefault();
    let fieldParent = link.closest(".maintenance-expirations-fields");
    let deleteField = fieldParent ? fieldParent.querySelector('.destroy-maintenance') : null;
    if (deleteField) {
      deleteField.value = 1;
      // Nasconde visivamente l'ordine
      fieldParent.style.display = "none";
    }
  }

  updateRemoveMaintenanceLinks() {

   // Trova il container degli ordini
   const maintenanceContainer = document.getElementById("maintenanceContainer");
  
   // Trova tutti gli ordini presenti
   const maintenances = maintenanceContainer.querySelectorAll('.maintenance-expirations-fields:not([style*="display: none"])');
   
   // Trova i link per rimuovere l'ordine
   const removeMaintenanceLinks = document.querySelectorAll('.remove-maintenance-fields');
   
   // Verifica se ci sono più di un ordine
   if (maintenances.length >= 2) {
     // Riattiva i link per rimuovere l'ordine
     removeMaintenanceLinks.forEach(link => {
       link.classList.remove('disabled');
       link.removeAttribute('disabled');
       link.closest('.remove-maintenance-fields').style.display = "block"; // Mostra visivamente l'ordine
     });
   } else {
   
     // Disattiva i link per rimuovere l'ordine
     removeMaintenanceLinks.forEach(link => {
       link.classList.add('disabled');
       link.setAttribute('disabled', 'disabled');
       link.closest('.remove-maintenance-fields').style.display = "none"; // Nasconde visivamente l'ordine
     });
   }
  }
}

window.addEventListener("turbolinks:load", () => new removeMaintenanceFields());
