import flatpickr from "flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";

const addMaintenance = () => {
  const createButton = document.getElementById("addMaintenance");
  createButton.addEventListener("click", () => {
    // Trova il container degli ordini
    const maintenanceContainer = document.getElementById("maintenanceContainer");
    
    // Crea un nuovo indice incrementale per la nuova chiave dell'ordine
    const newIndex = maintenanceContainer.querySelectorAll('.maintenance-expirations-fields').length;

    // Clona il primo fieldset e sostituisci l'ID con il nuovo ID
    const visibleFieldset = maintenanceContainer.querySelector('.maintenance-expirations-fields:not([style*="display: none"])');
    
    if (visibleFieldset) {
      const newFieldset = visibleFieldset.cloneNode(true);
      newFieldset.id = newIndex;

      // Modifica i nomi degli input all'interno del nuovo fieldset per includere il nuovo indice
      const inputs = newFieldset.querySelectorAll('input');
      inputs.forEach(input => {
        const name = input.getAttribute('name').replace(/\[\d\]/, `[${newIndex}]`);
        input.setAttribute('name', name);
      
        // Mantieni i valori per i campi nascosti specifici
        if (input.name.includes('company_id') || input.name.includes('maintenance_expiration_status')) {
          return; // Non svuotare il valore
        }
      
        input.value = ''; // Svuota il valore dell'input
      });
      
      const selects = newFieldset.querySelectorAll('select');
        selects.forEach(select => {
          select.name = select.name.replace(/\[\d\]/, `[${newIndex}]`);
          select.selectedIndex = 0;
        });

      // Aggiungi il nuovo fieldset al container degli ordini
      maintenanceContainer.appendChild(newFieldset);

      // Aggiorna lo stato dei link "rimuovi ordine"
      updateRemoveMaintenanceLinks();
      


      // Inizializza flatpickr per i nuovi elementi aggiunti
      flatpickr(newFieldset.querySelectorAll('.mydate'), {
        locale: Italian,
        dateFormat: "d-m-Y",
        allowInput: true
      });
      
    }
  });
}

const updateRemoveMaintenanceLinks = () => {
  // Trova il container degli ordini
  const maintenanceContainer = document.getElementById("maintenanceContainer");
  
  // Trova tutti gli ordini presenti
  const maintenances = maintenanceContainer.querySelectorAll('.maintenance-expirations-fields');
  
  // Trova i link per rimuovere l'ordine
  const removeMaintenanceLinks = document.querySelectorAll('.remove-maintenance-fields');
  
  // Verifica se ci sono più di un ordine
  if (maintenances.length >= 2) {
    // Riattiva i link per rimuovere l'ordine
    removeMaintenanceLinks.forEach(link => {
      link.classList.remove('disabled');
      link.removeAttribute('disabled');
      link.closest('.remove-maintenance-fields').style.display = "block"; // Mostra visivamente l'ordine
    });
  } else {
    // Disattiva i link per rimuovere l'ordine
    removeMaintenanceLinks.forEach(link => {
      link.classList.add('disabled');
      link.setAttribute('disabled', 'disabled');
      link.closest('.remove-maintenance-fields').style.display = "none"; // Nasconde visivamente l'ordine
    });
  }
}

export { addMaintenance, updateRemoveMaintenanceLinks };


