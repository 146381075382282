// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require cocoon

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "bootstrap/dist/js/bootstrap"
import "bootstrap/dist/css/bootstrap"

global.toastr = require("toastr")

import flatpickr from 'flatpickr'
import { Italian } from "flatpickr/dist/l10n/it.js"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "vanilla-nested"

document.addEventListener('turbolinks:load', ()=> {
    flatpickr('.mydate', {
      locale: Italian,
      dateFormat: "d-m-Y",
      allowInput: true
    })
})

import { addOrder } from './nested-forms/addOrder';
import { addHours } from './nested-forms/addHours';
import { addProduct } from './nested-forms/addProducts';
import { addPainting } from './nested-forms/addPaintings';
import { addDdtDetail } from './nested-forms/addDdtDetail';
import { addMaintenance } from './nested-forms/addMaintenance';

require("./nested-forms/removeOrderFields");
require("./nested-forms/removeHoursFields");
require("./nested-forms/removeProductFields");
require("./nested-forms/removePaintingFields");
require("./nested-forms/removeDdtDetailFields");
require("./nested-forms/removeMaintenanceFields");

document.addEventListener('turbolinks:load', ()=> {
  if (document.querySelector("#orderContainer")) {
    addOrder()
  }
});

document.addEventListener('turbolinks:load', ()=> {
  if (document.querySelector("#hoursContainer")) {
    addHours()
  }
})

document.addEventListener('turbolinks:load', ()=> {
  if (document.querySelector("#productContainer")) {
    addProduct()
  }
})

document.addEventListener('turbolinks:load', ()=> {
  if (document.querySelector("#paintingContainer")) {
    addPainting()
  }
})

document.addEventListener('turbolinks:load', ()=> {
  if (document.querySelector("#ddtdetailsContainer")) {
    addDdtDetail()
  }
})

document.addEventListener('turbolinks:load', ()=> {
  if (document.querySelector("#maintenanceContainer")) {
    addMaintenance()
  }
})

document.addEventListener('turbolinks:load', function () {
  var form = document.querySelector('form#amministrative_commission_form');
  var closeAndSubmitButton = document.getElementById('close_and_submit');

  if (!form) return;

  // Flags to track if the form has been submitted or "Salva e Chiudi" button has been clicked
  var formSubmitted = false;
  var closeAndSubmitClicked = false;

  // Set formSubmitted to true when the form is submitted
  form.addEventListener('submit', function () {
    formSubmitted = true;
  });

  // Set closeAndSubmitClicked to true when "Salva e Chiudi" button is clicked
  closeAndSubmitButton.addEventListener('click', function () {
    closeAndSubmitClicked = true;
    // Invia il form
    form.submit();
  });

  // Function to show confirmation message
  function showConfirmationMessage(event) {
    if (!formSubmitted && !closeAndSubmitClicked) {
      var message = 'Sei sicuro di voler lasciare questa pagina? I dati non salvati andranno persi.';
      event.returnValue = message;
      return message;
    }
  }

  // Add beforeunload event listener
  window.addEventListener('beforeunload', showConfirmationMessage);

  document.addEventListener('turbolinks:before-visit', function (event) {
    if (!formSubmitted && !closeAndSubmitClicked) {
      // Temporarily remove beforeunload event listener to prevent showing the message again
      window.removeEventListener('beforeunload', showConfirmationMessage);

      var message = 'Sei sicuro di voler lasciare questa pagina? I dati non salvati andranno persi.';
      if (!confirm(message)) {
        event.preventDefault();
        // Re-add beforeunload event listener back only if user cancels
        setTimeout(function() {
          window.addEventListener('beforeunload', showConfirmationMessage);
        }, 0);
      } else {
        // Disable the beforeunload event listener if user confirms
        formSubmitted = true;
      }
    }
  });

});

document.addEventListener('turbolinks:load', function () {
  const elements = document.querySelectorAll('.my-select');
  elements.forEach(element => { // Itera su ogni elemento con la classe my-select
      const choices = new Choices(element, {
          searchEnabled: true, // Abilita la ricerca
          placeholderValue: 'Select an option',
          removeItemButton: true, // Abilita il pulsante per rimuovere gli elementi
      });
  });
});


document.addEventListener('turbolinks:load', function() {
  // Seleziona tutti i pulsanti all'interno delle celle con classe 'toggle-cell'
  const toggleButtons = document.querySelectorAll('.toggle-cell .btn-toggle');

  toggleButtons.forEach(button => {
      button.addEventListener('click', function() {
          // Trova la riga successiva rispetto alla riga della cella cliccata
          const nextRow = this.closest('tr').nextElementSibling;
          
          // Verifica se la riga successiva è una 'ciao-row'
          if (nextRow && nextRow.classList.contains('ciao-row')) {
              // Cambia la visibilità della riga
              if (nextRow.style.display === 'none') {
                  nextRow.style.display = 'table-row';
                  this.textContent = '-'; // Cambia il simbolo a '-'
              } else {
                  nextRow.style.display = 'none';
                  this.textContent = '+'; // Ritorna al simbolo '+'
              }
          }
      });
  });
});
